/* TABLE STYLE */

table {
  width: 100%;
  font-size: x-small;
}

thead {
  text-align: center;
  font-family: CherryPie, sans-serif;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.3);
  color: white;

  tr {
    td {
      &:nth-child(1) {
        font-size: medium;
        background-color: #5BBFF5;
      }
      &:nth-child(2), &:nth-child(3) {
        font-size: x-small;
        background-color: #FFCB5C;
      }
      &:nth-child(4){
        font-size: x-small;
        background-color: #83ECFF;
      }
    }
  }
}

tbody {
  font-weight: bold;
  tr {
    td {
      padding: 2%;

      &:nth-child(1), &:nth-child(4){
        background-color: #def2fd;
      }
      &:nth-child(2){
        background-color: #ffe5ad;
      }
      &:nth-child(3){
        background-color: #ffefcb;
      }
    }
  }
}

tr {
  :first-child{
    width: 35%;
  }
}

td {
  border: 1px solid #CED5DB;
}