@font-face {
  font-display: auto;
  font-family: 'Rutan';
  src: url('../fonts/rutan.otf') format('opentype');
}

@font-face {
  font-display: auto;
  font-family: 'Rutan';
  font-weight: bold;
  src: url('../fonts/rutan-bold.otf') format('opentype');
}

@font-face {
  font-display: auto;
  font-family: 'Rutan';
  font-weight: 500;
  src: url('../fonts/rutan-medium.otf') format('opentype');
}

@font-face {
  font-display: auto;
  font-family: 'Rutan';
  src: url('../fonts/rutan.ttf') format('truetype');
}

@font-face {
  font-display: auto;
  font-family: 'Rutan';
  font-weight: bold;
  src: url('../fonts/rutan-bold.ttf') format('truetype');
}

@font-face {
  font-display: auto;
  font-family: 'Rutan';
  font-weight: 500;
  src: url('../fonts/rutan-medium.ttf') format('truetype');
}

@font-face {
  font-display: auto;
  font-family: 'CherryPie';
  font-weight: 500;
  src: url('../fonts/CherryPie.otf') format('truetype');
}