@import "assets/styles/fonts";
@tailwind variants;

/* VARIABLES */
$blue_light: #5bbff5;
$blue: #26affa;
$black: #363636;
$black_light: #4a4a4a;
$white: #d8d8d8;

#root {
  white-space: pre-line;
}

body {
  font-family: Rutan, sans-serif;
  color: $black_light;
}

/* Default */
 h1 {
   color: $black !important;
   font-weight: 600 !important;
   line-height: 1.125 !important;
 }

 h1 > p {
   font-size: 1rem !important;
 }

 h3 {
   font-size: 1.5rem !important;
   font-weight: bold;
 }

 h4 {
   color: $black_light !important;
   font-size: 1.25rem !important;
   font-weight: 400 !important;
 }

 .text-secondary {
   color: $blue !important;
 }

 .min-w-header-img {
   min-width: 315px;
 }

 p {
   color: #4a4a4a !important;
 }

 .instruction-img {
   height: 100px !important;
 }

 .carret-close {
   min-width: 12px;
   min-height: 12px;
   border-top: 2px;
   border-right: 2px;
   border-color: $white;
   border-style: solid;
   transform: rotate(45deg);
   transition: all .2s ease;
 }

.carret-open {
  min-width: 12px;
  min-height: 12px;
  border-top: 2px;
  border-right: 2px;
  border-color: $blue;
  border-style: solid;
  transform: rotate(135deg);
  transition: all .2s ease;
}

.panel-open {
  opacity: 1;
  visibility: visible;
  transition: all .2s ease;
}

.panel-close {
  opacity: 0;
  visibility: hidden;
  height: 0;
  transition: all .15s ease;
}

.email {
  color: $blue_light !important;
}